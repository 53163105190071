import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { BodySectionProjectsTemplatePg } from "../components/Projects-Page/projects-template-body"

const ProjectsTemplatePage = (props) => {
    return (
        <Layout>
            <Seo
                title={"Kaylar Construction Projects: Inspiring " + props.data.contentfulProjects.serviceName + " Transformations In " + props.data.contentfulProjects.city}
                description={"Discover our impressive project showcase for " + props.data.contentfulProjects.serviceName + " Transformations In " + props.data.contentfulProjects.city + ". Get inspired by our exceptional craftsmanship and envision the possibilities for your own home transformation."}
                location={props.location.pathname}
                keywords={[props.data.contentfulProjects.city + props.data.contentfulProjects.serviceName]}
            />
            <div>
                <BodySectionProjectsTemplatePg data={props.data.contentfulProjects} />
            </div>
        </Layout>
    )
}
export default ProjectsTemplatePage
export const pageQuery = graphql`
query ContentfulProjectsBySlug($slug: String !) {
    contentfulProjects( slug: {eq: $slug }) {
        slug
        mainImage {
            title
            description
            url
            gatsbyImageData (
              placeholder: NONE
              quality: 100
            )
          }
        city
        serviceName
        paragraph1{
            childMarkdownRemark {
                html
            }
        }
        sliderImages {
            id
            description
            gatsbyImageData
            url
            title
            description
        }
        date
        reason1
        reason2
        reason3
    }
}
`