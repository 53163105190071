import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css"
// import ZoomIcon from '../icons/zoom'
// import Lightbox from "react-image-lightbox"
// import "react-image-lightbox/style.css"
// import "../../styles/product-image-gallery.css"

export class ServicesImageGallery extends Component {
    constructor(props) {
        super()
        this.state = {
            // showVideo: {},
            current: ''
        }

        this.images = []
        // if (props.videoUrl && props.videoUrl != 'NULL') {
        //     this.images.push({
        //         original: props.imagesGraph[0].url,
        //         thumbnail: 'https://images.ctfassets.net/nqwi7rhw6o1n/aPal627L9oSIJMFHV9LnJ/e12ae42a918abd0e9edd04d695fe83be/play-icon.png',
        //         embedUrl: props.videoUrl,
        //         renderItem: this._renderVideo.bind(this),
        //     })
        // }

        if (props.imagesGraph) {
            props.imagesGraph.forEach((image) => {
                this.images.push({ original: image.url, thumbnail: image.url, embedUrl: image.embedUrl, originalTitle: props.title, originalAlt: props.title })
            })
        }

    }

    handleClickImage(e, image) {
        if (image.embedUrl) {
            return
        }
        e && e.preventDefault()

        this.setState({
            current: image
        })
    }

    handleCloseModal = e => {
        e && e.preventDefault()

        this.setState({
            current: ''
        })
    }

    _onImageLoad(event) {
        console.debug('loaded image', event.target.src);
    }

    _onSlide(index) {
        // this._resetVideo();
        console.debug('slid to index', index);
    }

    _onPause(index) {
        console.debug('paused on index', index);
    }

    _onScreenChange(fullScreenElement) {
        console.debug('isFullScreen?', !!fullScreenElement);
    }

    // _resetVideo() {
    //     this.setState({ showVideo: {} });

    //     if (this.state.showFullscreenButton) {
    //         this.setState({ showGalleryFullscreenButton: true });
    //     }
    // }

    // _toggleShowVideo(url) {
    //     this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);

    //     this.setState({
    //         showVideo: this.state.showVideo
    //     })

    //     if (this.state.showVideo[url]) {
    //         if (this.state.showFullscreenButton) {
    //             this.setState({ showGalleryFullscreenButton: true });
    //         }
    //     }
    // }

    // _renderVideo(item) {
    //     return (
    //         <div>
    //             {
    //                 this.state.showVideo[item.embedUrl] ?
    //                     <div className='video-wrapper'>
    //                         <button
    //                             className='close-video'
    //                             onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
    //                         >
    //                         </button>
    //                         <iframe
    //                             title='Video'
    //                             width='560'
    //                             height='315'
    //                             src={item.embedUrl}
    //                             frameBorder='0'
    //                             allowFullScreen
    //                         >
    //                         </iframe>
    //                     </div>
    //                     :
    //                     <button onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
    //                         <div className='play-button'></div>
    //                         <img className='image-gallery-image' src={item.original} title={this.props.title} alt={this.props.title} />
    //                         {
    //                             item.description &&
    //                             <span
    //                                 className='image-gallery-description'
    //                                 style={{ right: '0', left: 'initial' }}
    //                             >
    //                                 {item.description}
    //                             </span>
    //                         }
    //                     </button>
    //             }
    //         </div>
    //     )
    // }

    render() {
        // var { current } = this.state

        return (
            <div>
                <div className='2xl:hidden'>
                    <ImageGallery
                        ref={i => this._imageGallery = i}
                        items={this.images}
                        onImageLoad={this._onImageLoad}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        useBrowserFullscreen={false}
                        onSlide={this._onSlide.bind(this)}
                        onPause={this._onPause.bind(this)}
                        showPlayButton={false}
                        onClick={e => this.handleClickImage(e, this.images[this._imageGallery.getCurrentIndex()])}
                    />

                    {/* {current && (
                    <Lightbox mainSrc={current.original} onCloseRequest={this.handleCloseModal} />
                )}
                <ZoomIcon /> */}
                </div>
                <div className='hidden 2xl:flex'>
                    <ImageGallery
                        ref={i => this._imageGallery = i}
                        items={this.images}
                        onImageLoad={this._onImageLoad}
                        showThumbnails={this.images.length > 1}
                        showFullscreenButton={true}
                        useBrowserFullscreen={true}
                        onSlide={this._onSlide.bind(this)}
                        onPause={this._onPause.bind(this)}
                        showPlayButton={false}
                        onClick={e => this.handleClickImage(e, this.images[this._imageGallery.getCurrentIndex()])}
                    />

                    {/* {current && (
                    <Lightbox mainSrc={current.original} onCloseRequest={this.handleCloseModal} />
                )}
                <ZoomIcon /> */}
                </div>
            </div>
        )
    }
}