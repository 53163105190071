import React, { Component } from "react"
import Breadcrumb from '../../components/breadcrumb'
import { Link } from "gatsby"
import { ServicesImageGallery } from "../Services-Page/services-image-gallery"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ShareButtons from "../share-buttons"

export class BodySectionProjectsTemplatePg extends Component {
    render() {
        const projectData = this.props.data
        const url = "https://kaylarconstruction.com/" + projectData.slug
        let serviceLinkTo = '/'
        if (projectData.serviceName === 'Kitchen Remodel') {
            serviceLinkTo = '/kitchen-remodel'
        }
        else if (projectData.serviceName === 'Bathroom Remodel') {
            serviceLinkTo = '/bathroom-remodel'
        }
        else if (projectData.serviceName === 'Siding') {
            serviceLinkTo = '/siding'
        }
        else if (projectData.serviceName === 'Window Replacement') {
            serviceLinkTo = '/window-replacement'
        }
        else if (projectData.serviceName === 'Flooring') {
            serviceLinkTo = '/flooring'
        }
        else if (projectData.serviceName === 'Fire & Water Restoration') {
            serviceLinkTo = '/fire-water-restoration'
        }
        else if (projectData.serviceName === 'New Construction') {
            serviceLinkTo = '/new-construction'
        }
        else if (projectData.serviceName === 'Painting') {
            serviceLinkTo = '/painting'
        }
        return (
            <div>
                <GatsbyImage image={projectData.mainImage.gatsbyImageData} className="object-cover h-[700px] w-full" placeholder="none" title={projectData.mainImage.title} alt={projectData.mainImage.title} />
                <div className="bg-white border border-gray-200 shadow-lg">
                    <nav className="flex container mx-auto px-4 pt-1 pb-3" aria-label="Breadcrumb">
                        <Breadcrumb links={[
                            { to: '/', label: 'Home' },
                            { to: '/projects', label: 'Projects' },
                            { to: projectData.slug, label: projectData.serviceName + ' - ' + projectData.city },
                        ]} />
                    </nav>
                </div>
                <section className="project-page py-12 md:py-12 bg-gray">
                    <div className="container mx-auto">
                        <div className="px-5">
                            <div className="section-subtitle">{projectData.city}</div>
                            <div className="section-title"><h1 className="text-2xl lg:text-3xl">{projectData.serviceName}</h1></div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="md:w-3/4 pr-4 pl-4">
                                <div className="flex flex-wrap">
                                    <div className="" dangerouslySetInnerHTML={{
                                        __html: projectData.paragraph1.childMarkdownRemark.html,
                                    }}>
                                    </div>
                                    <div className="flex flex-wrap mb-30">
                                        <div className="px-2 max-w-[37rem] md:max-w-[47rem] lg:max-w-[63rem] mx-auto">
                                            <ServicesImageGallery imagesGraph={projectData.sliderImages} />
                                        </div>
                                    </div>
                                </div>
                                <h5>Why choose our {projectData.serviceName} services?</h5>
                                <ol className="number">
                                    <li>{projectData.reason1}</li>
                                    <li>{projectData.reason2}</li>
                                    <li>{projectData.reason3}</li>
                                </ol>
                            </div>

                            <div className="md:w-1/5 pr-4 pl-4 md:mx-1/6">
                                <ul className="content nor-list nor-list-divider">
                                    <li className="date">
                                        <strong>Date</strong>
                                        <span>{projectData.date}</span>
                                    </li>
                                    <li className="category">
                                        <strong>Category</strong>
                                        <span><Link to={serviceLinkTo} className="hover:text-lightblue transition duration-300 ease-in-out" title={'View Our ' + projectData.serviceName + ' Services'} alt={'View Our ' + projectData.serviceName + ' Services'}>{projectData.serviceName}</Link></span>
                                    </li>
                                </ul>
                                <div className="pt-2 mb-5 -ml-1">
                                    <ShareButtons url={url} description={projectData.city + ' - ' + projectData.serviceName} title={projectData.city + ' - ' + projectData.serviceName + ' Services'} />
                                </div>
                                <AnchorLink to='/#services' title='View Our Services' alt='View Our Services'>
                                    <button className='relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-300 ease-in-out border-darkblue bg-darkblue text-white text-xs xl:text-sm hover:bg-lightblue hover:text-white'>
                                        <span>Our Services</span>
                                    </button>
                                </AnchorLink>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
